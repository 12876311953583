import React, { useState } from "react";
import {
  Container,
  Select,
  Input,
  Button,
  Segment,
  Modal,
} from "semantic-ui-react";
import DetailsLine from "../common/DetailsLine";
import helpers, { cancelToken } from "../../services/helpers";
import { useAuth0 } from "../../react-auth0-wrapper";
import countries from "../../assets/countries.json";

export default function AddComplyAdvantage(props) {
  const initState = {
    firstName: "",
    lastName: "",
    birthYear: "",
    country: "",
  };
  const { getIdToken } = useAuth0();
  const [showModal, setShowModal] = useState(false);
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [complyAdvantageInfo, setComplyAdvantageInfo] = useState(initState);
  const [touched, setTouched] = useState(initState);

  const countryList = [];
  if (countries.length > 0) {
    for (let i = 0; i < countries.length; i++) {
      countryList.push({
        key: countries[i].name,
        value: countries[i].alpha2,
        text: countries[i].name,
      });
    }
  }

  const onCloseModal = () => {
    setShowModal(false);
    setUrl("");
    setComplyAdvantageInfo(initState);
    setTouched(initState);
  };

  const handleInputChange = (field, value) => {
    setComplyAdvantageInfo((prev) => ({
      ...prev,
      [field]: value,
    }));

    setTouched((prev) => ({
      ...prev,
      [field]: true,
    }));
  };

  const addComplyAdvantage = () => {
    for (let key in complyAdvantageInfo) {
      if (complyAdvantageInfo[key] === "") {
        alert(`Please fill in the ${key}`);
        return;
      }
    }

    setLoading(true);
    const token = getIdToken();
    const source = cancelToken.source();
    helpers.auth0
      .complyAdvantage(token, source, complyAdvantageInfo)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          const msg = `ComplyAdvantage has been done successfully.`;
          setUrl(response.data.data);
          setShowModal(true);
          props.setDataToast({
            open: true,
            type: "success",
            message: msg,
            timeAutoHide: 6000,
          });
        } else {
          window.alert("Something went wrong");
        }
      })
      .catch((error) => {
        setLoading(false);
        const resp = JSON.parse(error.request.response);
        props.setDataToast({
          open: true,
          variant: "error",
          message: resp.code.message,
          timeAutoHide: 6000,
        });
      });
  };

  const copyUrl = () => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        props.setDataToast({
          open: true,
          type: "success",
          message: "URL copied to clipboard successfully!",
          timeAutoHide: 6000,
        });
        console.log();
      })
      .catch((err) => {
        props.setDataToast({
          open: true,
          variant: "error",
          message: `Failed to copy URL: ${err}`,
          timeAutoHide: 6000,
        });
      });
  };

  return (
    <Container textAlign="center" style={{ marginTop: "50px" }}>
      <Segment style={{ backgroundColor: "white", padding: "20px" }}>
        <h2>ComplyAdvantage</h2>
        <DetailsLine>
          <span className="ref-add-label">First Name</span>
          <Input
            error={touched.firstName && !complyAdvantageInfo.firstName}
            value={complyAdvantageInfo.firstName}
            style={{ width: "350px" }}
            onChange={(e) => handleInputChange("firstName", e.target.value)}
          />
        </DetailsLine>
        <DetailsLine>
          <span className="ref-add-label">Last Name</span>
          <Input
            error={touched.lastName && !complyAdvantageInfo.lastName}
            value={complyAdvantageInfo.lastName}
            style={{ width: "350px" }}
            onChange={(e) => handleInputChange("lastName", e.target.value)}
          />
        </DetailsLine>
        <DetailsLine>
          <span className="ref-add-label">Birth Year</span>
          <Input
            error={touched.birthYear && !complyAdvantageInfo.birthYear}
            value={complyAdvantageInfo.birthYear}
            style={{ width: "350px" }}
            onChange={(e) => handleInputChange("birthYear", e.target.value)}
            maxLength="4"
          />
        </DetailsLine>
        <DetailsLine>
          <span className="ref-add-label">Residency</span>
          <Select
            error={touched.country && !complyAdvantageInfo.country}
            value={complyAdvantageInfo.country}
            placeholder="Select Residency"
            style={{ width: "350px" }}
            options={countryList}
            onChange={(e, data) => handleInputChange("country", data.value)}
            search
            selection
          />
        </DetailsLine>
        <Button
          positive
          disabled={loading}
          loading={loading}
          onClick={addComplyAdvantage}
        >
          Submit
        </Button>
      </Segment>
      <Modal open={showModal} onClose={onCloseModal} style={{ width: "60%" }}>
        <Modal.Header>Success</Modal.Header>
        <Modal.Content>
          <div style={{ textAlign: "center" }}>
            <h3>ComplyAdvantage URL</h3>
            <p>{url}</p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button positive onClick={copyUrl}>
            Copy URL
          </Button>
          <Button
            onClick={() => onCloseModal()}
            style={{ backgroundColor: "#fa4261", color: "white" }}
          >
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </Container>
  );
}
